@import '../../../styles/colors.scss';

.footerRow {
  border-radius: 0 !important;
  padding: 0 !important;

  .footerActions {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    background: transparent;
  }
}

.Container {
  position: relative;
  flex: 1 1;

  .TableHeaderActions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    height: 50px;
    padding: 0 10px;
    background: white;
    top: 0;
    z-index: 3;
    // border-bottom: 1px solid rgba(34,36,38,.1);

    .TableHeader {
      display: flex;

      .headerText {
        margin-bottom: 0;
      }

      .refreshBtn {
        cursor: pointer;
        margin-left: 15px;
      }
    }

    .TableActionsButton {
      border: 1px solid rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      box-shadow: 1px 1px 5px -2px rgba(0, 0, 0, 0.3);
      background: #C0DDE0 !important;
      color: black;
    }
  }

  .Table {
    border-radius: 0 !important;
    margin: 0 !important;

    thead tr.FilterRow th {
      position: sticky !important;
      top: 50px;
      z-index: 3;
      padding: 0;

      .SearchInput {
        width: 100%;

        input {
          padding: .5em 1em;
          border-radius: 0;
        }
      }
    }

    thead tr.HeaderRow th {
      position: sticky !important;
      top: 84px;
      z-index: 3;
      border-left: none !important;
      border-right: none !important;
    }

    thead tr.FilterRowWithoutActions th {
      position: sticky !important;
      top: 0px;
      z-index: 3;
      padding: 0;
    }

    thead tr.HeaderRowWithoutActions th {
      position: sticky !important;
      top: 0px;
      z-index: 3;
      // border-left: none !important;
      // border-right: none !important;
    }

    tfoot tr th {
      position: sticky !important;
      bottom: 0;
      z-index: 2;
    }

    p.noData {
      text-align: center;
      font-size: 2rem;
      opacity: 0.3;
    }

    :global {
      .content.active {
        display: table-row !important;
      }
    }

    .spaceTableRow {
      border: none !important;

      .spaceBetween {
        background: #DEDFDF !important;
        border: none !important;
        padding: 5px;
      }
    }

    .expandedTitleRow {
      .cellContent {
        border-bottom: none !important;
      }
    }

    .expandedContentRow {
      .cellContent {
        border-top: none !important;
      }
    }

    .expandedContentRow,
    .expandedTitleRow,
    .cellContent,
    .tableRow {
      background: white !important;
    }

    .Accordion {
      width: 100%;
    }

    .cell {
      width: intrinsic;
      width: -moz-max-content;
      width: -webkit-max-content;
      user-select: text;
      -webkit-user-select: text;
      -khtml-user-select: text;
      -moz-user-select: text;
      -ms-user-select: text;
      cursor: text;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}