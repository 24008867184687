body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eaedef;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:global {
  .Toastify__toast--info {
      background-color: #C0DDE0 !important;

  }

  .Toastify__toast--error {
      background-color: #f85e7c !important;
  }

  .Toastify__progress-bar--info {
      background: rgba(34, 36, 38, .45) !important;
  }

  .Toastify__toast--info button{
      color: #000;
  }
}