@import "../../../styles/colors.scss";

.ToolbarSegment {
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 0 !important;
  border: 1px solid rgba(34, 36, 38, 0.05) !important;
  position: fixed !important;
  top: 0;
  z-index: 100 !important;
  width: 100%;

  .ToolbarMenu {
    background-color: $rpm-dark-blue-shade3 !important;
    margin: 0;
    border-radius: 0;
    box-shadow: 0 1px 3px 0px black !important;
    z-index: 100;
    display: flex;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    flex: 1;

    .LogoItem {
      padding: 3px 20px 3px 3px !important;

      .logoLink {
        display: flex;
        align-items: center;
        height: 100%;
        width: max-content;

        .logo {
          display: flex;
          height: 40px;
          width: 150px;
          align-items: center;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          background-image: url("../../../../public/assets/images/RPM_logo_rev.png");
        }
      }
    }

    .AuthItem {
      padding: 3px !important;
    }

    .VersionItem {
      display: flex;
    }

    .MenuItem.active {
      border-color: $rpm-detroit-blue-tint1 !important;
    }

    .grow {
      flex-grow: 1;
    }
  }
}

.userIcon {
  margin: 0 !important;
}

.userDropdown {
  padding-left: 7px !important;
}
