@import '../../../styles/colors.scss';

.TableActions {
    display: flex;
    justify-content: flex-end;

    :global {
        .ui.secondary.pointing.menu .active.item,
        .ui.secondary.pointing.menu .active.item:hover {
            border-color: $rpm-detroit-blue-tint1 !important;
        }
    }

    .PageSizeSelect {
        min-width: 5em !important;
        border: none !important;
        margin-left: 10px;
    }
}